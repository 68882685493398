import { INetworkConfig } from "config/config";
import { useQuery, UseQueryOptions } from "react-query";
import { request, gql } from "graphql-request";

export default function useNetworkUniqueUsersQuery(
  networkConfig: INetworkConfig,
  options?: UseQueryOptions<string, Error, string, string[]>
) {
  return useQuery(
    ["uniqueUsers", networkConfig.networkName],
    async () => {
      const subgraphResponse = await request(
        networkConfig.subgraphEndpoint,
        gql`
          query {
            uniqueWalletCount(id: "0") {
              count
            }
          }
        `
      );

      return subgraphResponse.uniqueWalletCount.count as string;
    },
    {
      ...options,
    }
  );
}
