import React from "react";

import AvalancheDataCard from "components/DataCards/AvalancheDataCard";
import { AvalancheNetworkConfig } from "config/config";
import useNetworkUniqueUsersQuery from "hooks/query/uniqueUsers/NetworkUniqueUsersQuery";

const AvalancheUniqueUsers: React.FC = (props) => {
  const avalancheUniqueUsersQuery = useNetworkUniqueUsersQuery(AvalancheNetworkConfig);

  return (
    <AvalancheDataCard
      title="Unique Users"
      body={avalancheUniqueUsersQuery.data || "..."}
    />
  );
};

export default AvalancheUniqueUsers;
