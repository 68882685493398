import EthereumDataCard from "components/DataCards/EthereumDataCard";
import { EthereumNetworkConfig } from "config/config";
import useNetworkDayFiatVolumeQuery from "hooks/query/volume/NetworkDayFiatVolumeQuery";
import useNetworkDayVolumeQuery, {
  NetworkDayFiatVolume,
  NetworkDayVolume,
} from "hooks/query/volume/NetworkDayVolumeQuery";
import React, { useMemo } from "react";

const EthereumDayVolume: React.FC = ({}) => {
  const ethereumDayVolumeQuery = useNetworkDayFiatVolumeQuery(
    EthereumNetworkConfig
  );

  // console.log(ethereumDayVolumeQuery.data);

  const dayVolume = useMemo(() => {
    if (!ethereumDayVolumeQuery.data) return undefined;

    const dayVolumeFloat = (
      ethereumDayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    return "$ " + parseInt(dayVolumeFloat.toString()).toLocaleString("en-US");
  }, [ethereumDayVolumeQuery.data]);

  return <EthereumDataCard title="24 Hour Volume" body={dayVolume || "..."} />;
};

export default EthereumDayVolume;
