import { BICO, ETH, ITokenConfig, USDC, USDT } from "./tokens";
import AvalancheNetworkIcon from "assets/images/avalanche-coloured.png";
import PolygonNetworkIcon from "assets/images/polygon-coloured.png";
import EthereumNetworkIcon from "assets/images/ethereum-coloured.png";

export interface INetworkConfig {
  liquidityPoolContractAddress: string;
  liquidityProvidersContractAddress: string;
  subgraphEndpoint: string;
  networkName: string;
  networkIcon: string;
  coinGeckoId: string;
  tokens: INetworkTokenConfig[];
  networkId: number;
  rpcUrl: string;
}

export interface INetworkTokenConfig {
  symbol: string;
  contractAddress: string;
  decimals: number;
  coinGeckoId: string;
}

let EthereumNetworkConfig: INetworkConfig;
let PolygonNetworkConfig: INetworkConfig;
let AvalancheNetworkConfig: INetworkConfig;

export function getTokenIdToContractAddressMapping(uniqueTokenAddresses: string[], networkConfig: INetworkConfig) {
  const tokenIdToTokenAddressMapping: { [tokenAddress: string]: string } & { [tokenId: string]: string } =
    {};
  const networkTokens = getNetworkTokens(networkConfig.networkId);

  for (const tokenAddress of uniqueTokenAddresses) {
    const networkToken = networkTokens.find(
      (token) => token.contractAddress.toLowerCase() === tokenAddress.toLowerCase()
    )

    const coinGeckoId = networkToken!.coinGeckoId;

    tokenIdToTokenAddressMapping[tokenAddress] = coinGeckoId;
    tokenIdToTokenAddressMapping[coinGeckoId] = tokenAddress;
  }

  return tokenIdToTokenAddressMapping;
}

export function getNetworkTokens(networkId: number): INetworkTokenConfig[] {
  const tokens: ITokenConfig[] = [ETH, USDT, USDC, BICO].filter((token) => !!(token as any)[networkId]);
  return tokens.map((token) => {
    return {
      ...token[networkId],
      decimals: Number(token[networkId].decimals),
      symbol: token.symbol,
      coinGeckoId: token.coinGeckoId
    } as INetworkTokenConfig;
  })
}

PolygonNetworkConfig = {
  networkName: "Polygon",
  networkIcon: PolygonNetworkIcon,
  coinGeckoId: "polygon-pos",
  networkId: 137,
  liquidityPoolContractAddress: "0x2A5c2568b10A0E826BfA892Cf21BA7218310180b",
  liquidityProvidersContractAddress: "0xebaB24F13de55789eC1F3fFe99A285754e15F7b9",
  subgraphEndpoint: "https://api.thegraph.com/subgraphs/name/shantanu-bico/hyphenv2-liquidity-pool-polygon",
  rpcUrl: "https://polygon-rpc.com/",
  tokens: getNetworkTokens(137)
}

const MumbaiNetworkConfig: INetworkConfig = {
  networkName: "Mumbai",
  networkIcon: PolygonNetworkIcon,
  coinGeckoId: "polygon-pos",
  networkId: 80001,
  liquidityPoolContractAddress: "0xDe4e4CDa407Eee8d9E76261a1F2d229A572743dE",
  liquidityProvidersContractAddress: "0xFD210117F5b9d98Eb710295E30FFF77dF2d80002",
  subgraphEndpoint: "https://api.thegraph.com/subgraphs/name/shantanu-bico/hyphenv2-liquidity-pool-mumbai",
  rpcUrl: "https://rpc-mumbai.matic.today",
  tokens: getNetworkTokens(80001)
}

AvalancheNetworkConfig = {
  networkName: "Avalanche",
  networkIcon: AvalancheNetworkIcon,
  networkId: 43114,
  coinGeckoId: "avalanche",
  liquidityPoolContractAddress: "0x2A5c2568b10A0E826BfA892Cf21BA7218310180b",
  liquidityProvidersContractAddress: "0xebaB24F13de55789eC1F3fFe99A285754e15F7b9",
  subgraphEndpoint: "https://api.thegraph.com/subgraphs/name/shantanu-bico/hyphenv2-liquidity-pool-avalanche",
  rpcUrl: "https://api.avax.network/ext/bc/C/rpc",
  tokens: getNetworkTokens(43114)
}

const FujiNetworkConfig: INetworkConfig = {
  networkName: "Fuji",
  networkIcon: AvalancheNetworkIcon,
  networkId: 43113,
  coinGeckoId: "avalanche",
  liquidityPoolContractAddress: "0xB726675394b2dDeE2C897ad31a62C7545Ad7C68D",
  liquidityProvidersContractAddress: "0x17D42A784928a8168a871fA627bb1e4023D25C2A",
  subgraphEndpoint: "https://api.thegraph.com/subgraphs/name/shantanu-bico/hyphenv2-liquidity-pool-fuji",
  rpcUrl: "https://api.avax-test.network/ext/bc/C/rpc",
  tokens: getNetworkTokens(43113)
}

EthereumNetworkConfig = {
  networkName: "Ethereum",
  networkIcon: EthereumNetworkIcon,
  networkId: 1,
  coinGeckoId: "ethereum",
  liquidityPoolContractAddress: "0x2A5c2568b10A0E826BfA892Cf21BA7218310180b",
  liquidityProvidersContractAddress: "0xebaB24F13de55789eC1F3fFe99A285754e15F7b9",
  subgraphEndpoint: "https://api.thegraph.com/subgraphs/name/shantanu-bico/hyphenv2-liquidity-pool-ethereum",
  rpcUrl: "https://eth-mainnet.alchemyapi.io/v2/LFk65HzNnmu9FfqsFFYs539UYlj_vZad",
  tokens: getNetworkTokens(1)
}

const GoerliNetworkConfig: INetworkConfig = {
  networkName: "Goerli",
  networkIcon: EthereumNetworkIcon,
  networkId: 5,
  coinGeckoId: "ethereum",
  liquidityPoolContractAddress: "0x8033Bd14c4C114C14C910fe05Ff13DB4C481a85D",
  liquidityProvidersContractAddress: "0x658D3F3076e971a74b2712Cf6e9B951BdB2f3fe8",
  subgraphEndpoint: "https://api.thegraph.com/subgraphs/name/shantanu-bico/hyphenv2-liquidity-pool-goerli",
  rpcUrl: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  tokens: getNetworkTokens(5)
}

if (process.env.REACT_APP_TESTNET) {
  EthereumNetworkConfig = GoerliNetworkConfig;
  AvalancheNetworkConfig = FujiNetworkConfig;
  PolygonNetworkConfig = MumbaiNetworkConfig;
}

export {
  EthereumNetworkConfig,
  AvalancheNetworkConfig,
  PolygonNetworkConfig
}