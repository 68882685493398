import EthereumDataCard from "components/DataCards/EthereumDataCard";
import { EthereumNetworkConfig } from "config/config";
import useNetworkTotalFiatVolumeQuery, {
  NetworkTotalFiatVolume,
} from "hooks/query/volume/NetworkTotalFiatVolumeQuery";
import React, { useMemo } from "react";

interface IEthereumTotalVolumeProps {}

const EthereumTotalVolume: React.FunctionComponent<
  IEthereumTotalVolumeProps
> = (props) => {
  const ethereumTotalVolumeQuery = useNetworkTotalFiatVolumeQuery(
    EthereumNetworkConfig
  );

  // console.log(ethereumDayVolumeQuery.data);

  const cumulativeVolume = useMemo(() => {
    if (!ethereumTotalVolumeQuery.data) return undefined;

    return (ethereumTotalVolumeQuery.data as NetworkTotalFiatVolume[]).reduce(
      (acc, curr) => {
        return acc + curr.cumulativeFiatAmount || 0;
      },
      0
    );
  }, [ethereumTotalVolumeQuery.data]);

  return (
    <EthereumDataCard
      title="Total Volume"
      body={
        cumulativeVolume
          ? `$ ${Number(cumulativeVolume.toFixed(0)).toLocaleString("en-US")}`
          : "..."
      }
    />
  );
};

export default EthereumTotalVolume;
