import React, { useMemo } from "react";

import PolygonDataCard from "components/DataCards/PolygonDataCard";
import { PolygonNetworkConfig } from "config/config";
import { UseQueryResult } from "react-query";
import { ResponsiveBar } from "@nivo/bar";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import LiquidityGraphCard from "components/LiquidityGraphCard";

const PolygonLiquidityEthereum: React.FC = (props) => {
  const polygonLiquidity = useNetworkTokensLiquidityQuery(PolygonNetworkConfig);

  return (
    <LiquidityGraphCard
      data={polygonLiquidity.data?.filter(
        (tokenLiquidity) => tokenLiquidity.tokenSymbol === "ETH"
      )}
      title={`${PolygonNetworkConfig.networkName} Liquidity`}
    />
  );
};

export default PolygonLiquidityEthereum;
