import React from "react";

import DataCard from "components/DataCards/DataCard";
import useUniqueUsersQuery from "hooks/query/uniqueUsers/UniqueUsersQuery";

const OverallUniqueUsers: React.FC = (props) => {
  const overallUniqueUsersQuery = useUniqueUsersQuery();

  return (
    <DataCard
      title="Unique Users"
      body={overallUniqueUsersQuery.data || "..."}
    />
  );
};

export default OverallUniqueUsers;
