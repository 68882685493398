import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { ethers } from "ethers";
import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import { ITokenConfig, tokens } from "config/tokens";
import SLVsALGraphCard from "components/SLVsALGraphCard";
import RewardPoolBalanceGraphCard from "components/RewardPoolBalanceGraphCard";

interface IAssetDeposit {
  averageRewardAmountPercent: string;
}

interface IAssetSentEntry {
  averageLpFeePercent: string;
  averageTransferFeePercent: string;
  averageGasFeePercent: string;
}

interface IExitFeeTableRow {
  network: string;
  tokenSymbol: string;
  averageAvailableLiquidity: number;
  averageSuppliedLiquidity: number;
  averageState: number;
  averageGasFeePercent: number;
  averageLpFeePercent: number;
  averageRebalancingFeePercent: number;
  averageRewardAmountPercent: number;
}

function SuppliedVsAvailableLiquidity({ token }: { token: ITokenConfig }) {
  const { data: liquidityDataOnEthereum } = useQuery(
    [
      "liquidityDataOnEthereum",
      token[EthereumNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
        await request(
          EthereumNetworkConfig.subgraphEndpoint,
          gql`
            query {
              availableLiquidityLogEntries(
                where: {
                tokenAddress: "${token[
                  EthereumNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              }
                orderBy: timestamp
                orderDirection: desc
              ) {
                timestamp
                tokenAddress
                availableLiquidity
              }
              hourlySuppliedLiquidity(
                id: "${token[
                  EthereumNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              ) {
                suppliedLiquidity
              }
            }
          `
        );
      return { availableLiquidityLogEntries, hourlySuppliedLiquidity };
    },
    {
      enabled: !!token[EthereumNetworkConfig.networkId]?.contractAddress,
    }
  );

  let graphDataOnEthereum = undefined;
  if (liquidityDataOnEthereum) {
    const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
      liquidityDataOnEthereum;
    const { suppliedLiquidity } = hourlySuppliedLiquidity ?? {};
    const tokenDecimals = token[EthereumNetworkConfig.networkId].decimals;
    const formattedSuppliedLiquidity = suppliedLiquidity
      ? Number.parseFloat(
          ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
        )
      : 0;
    const availableLiquidityArray = availableLiquidityLogEntries
      ? availableLiquidityLogEntries
          .slice(0, 20)
          .reverse()
          .map(
            ({
              timestamp,
              availableLiquidity,
            }: {
              timestamp: string;
              availableLiquidity: string;
            }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: ethers.utils.formatUnits(availableLiquidity, tokenDecimals),
            })
          )
      : [];
    const suppliedLiquidityArray =
      availableLiquidityLogEntries && suppliedLiquidity
        ? availableLiquidityLogEntries
            .slice(0, 20)
            .reverse()
            .map(({ timestamp }: { timestamp: string }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: formattedSuppliedLiquidity,
            }))
        : [];
    graphDataOnEthereum =
      availableLiquidityArray && suppliedLiquidityArray
        ? [
            {
              id: "Available",
              color: "hsl(223, 70%, 50%)",
              data: availableLiquidityArray,
            },
            {
              id: "Supplied",
              color: "hsl(335, 70%, 50%)",
              data: suppliedLiquidityArray,
            },
          ]
        : [];
  }

  const { data: liquidityDataOnPolygon } = useQuery(
    [
      "liquidityDataOnPolygon",
      token[PolygonNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
        await request(
          PolygonNetworkConfig.subgraphEndpoint,
          gql`
            query {
              availableLiquidityLogEntries(
                where: {
                tokenAddress: "${token[
                  PolygonNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              }
                orderBy: timestamp
                orderDirection: desc
              ) {
                timestamp
                tokenAddress
                availableLiquidity
              }
              hourlySuppliedLiquidity(
                id: "${token[
                  PolygonNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              ) {
                suppliedLiquidity
              }
            }
          `
        );
      return { availableLiquidityLogEntries, hourlySuppliedLiquidity };
    },
    {
      enabled: !!token[PolygonNetworkConfig.networkId]?.contractAddress,
    }
  );

  let graphDataOnPolygon = undefined;
  if (liquidityDataOnPolygon) {
    const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
      liquidityDataOnPolygon;
    const { suppliedLiquidity } = hourlySuppliedLiquidity ?? {};
    const tokenDecimals = token[PolygonNetworkConfig.networkId].decimals;
    const formattedSuppliedLiquidity = suppliedLiquidity
      ? Number.parseFloat(
          ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
        )
      : 0;
    const availableLiquidityArray = availableLiquidityLogEntries
      ? availableLiquidityLogEntries
          .slice(0, 20)
          .reverse()
          .map(
            ({
              timestamp,
              availableLiquidity,
            }: {
              timestamp: string;
              availableLiquidity: string;
            }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: ethers.utils.formatUnits(availableLiquidity, tokenDecimals),
            })
          )
      : [];
    const suppliedLiquidityArray =
      availableLiquidityLogEntries && suppliedLiquidity
        ? availableLiquidityLogEntries
            .slice(0, 20)
            .reverse()
            .map(({ timestamp }: { timestamp: string }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: formattedSuppliedLiquidity,
            }))
        : [];
    graphDataOnPolygon =
      availableLiquidityArray && suppliedLiquidityArray
        ? [
            {
              id: "Available",
              color: "hsl(223, 70%, 50%)",
              data: availableLiquidityArray,
            },
            {
              id: "Supplied",
              color: "hsl(335, 70%, 50%)",
              data: suppliedLiquidityArray,
            },
          ]
        : [];
  }

  const { data: liquidityDataOnAvalanche } = useQuery(
    [
      "liquidityDataOnAvalanche",
      token[AvalancheNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
        await request(
          AvalancheNetworkConfig.subgraphEndpoint,
          gql`
            query {
              availableLiquidityLogEntries(
                where: {
                tokenAddress: "${token[
                  AvalancheNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              }
                orderBy: timestamp
                orderDirection: desc
              ) {
                timestamp
                tokenAddress
                availableLiquidity
              }
              hourlySuppliedLiquidity(
                id: "${token[
                  AvalancheNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              ) {
                suppliedLiquidity
              }
            }
          `
        );
      return { availableLiquidityLogEntries, hourlySuppliedLiquidity };
    },
    {
      enabled: !!token[AvalancheNetworkConfig.networkId]?.contractAddress,
    }
  );

  let graphDataOnAvalanche = undefined;
  if (liquidityDataOnAvalanche) {
    const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
      liquidityDataOnAvalanche;
    const { suppliedLiquidity } = hourlySuppliedLiquidity ?? {};
    const tokenDecimals = token[AvalancheNetworkConfig.networkId].decimals;
    const formattedSuppliedLiquidity = suppliedLiquidity
      ? Number.parseFloat(
          ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
        )
      : 0;
    const availableLiquidityArray = availableLiquidityLogEntries
      ? availableLiquidityLogEntries
          .slice(0, 20)
          .reverse()
          .map(
            ({
              timestamp,
              availableLiquidity,
            }: {
              timestamp: string;
              availableLiquidity: string;
            }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: ethers.utils.formatUnits(availableLiquidity, tokenDecimals),
            })
          )
      : [];
    const suppliedLiquidityArray =
      availableLiquidityLogEntries && suppliedLiquidity
        ? availableLiquidityLogEntries
            .slice(0, 20)
            .reverse()
            .map(({ timestamp }: { timestamp: string }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: formattedSuppliedLiquidity,
            }))
        : [];
    graphDataOnAvalanche =
      availableLiquidityArray && suppliedLiquidityArray
        ? [
            {
              id: "Available",
              color: "hsl(223, 70%, 50%)",
              data: availableLiquidityArray,
            },
            {
              id: "Supplied",
              color: "hsl(335, 70%, 50%)",
              data: suppliedLiquidityArray,
            },
          ]
        : [];
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
      {token[EthereumNetworkConfig.networkId] ? (
        <SLVsALGraphCard
          data={graphDataOnEthereum}
          title={`SL vs AL (${token.symbol} on Ethereum)`}
        />
      ) : null}

      {token[PolygonNetworkConfig.networkId] ? (
        <SLVsALGraphCard
          data={graphDataOnPolygon}
          title={`SL vs AL (${token.symbol} on Polygon)`}
        />
      ) : null}

      {token[AvalancheNetworkConfig.networkId] ? (
        <SLVsALGraphCard
          data={graphDataOnAvalanche}
          title={`SL vs AL (${token.symbol} on Avalanche)`}
        />
      ) : null}
    </div>
  );
}

function RewardPoolBalance({ token }: { token: ITokenConfig }) {
  const { data: poolBalanceOnEthereumData } = useQuery(
    [
      "poolBalanceOnEthereum",
      token[EthereumNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const { incentivePoolBalanceLogEntries } = await request(
        EthereumNetworkConfig.subgraphEndpoint,
        gql`
          query {
            incentivePoolBalanceLogEntries(
              where: {
                tokenAddress: "${token[
                  EthereumNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              }
              orderBy: timestamp
              orderDirection: desc
            ) {
              timestamp
              poolBalance
            }
          }
        `
      );
      return incentivePoolBalanceLogEntries;
    },
    {
      enabled: !!token[EthereumNetworkConfig.networkId]?.contractAddress,
    }
  );

  const { data: poolBalanceOnPolygonData } = useQuery(
    [
      "poolBalanceOnPolygon",
      token[PolygonNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const { incentivePoolBalanceLogEntries } = await request(
        PolygonNetworkConfig.subgraphEndpoint,
        gql`
          query {
            incentivePoolBalanceLogEntries(
              where: {
                tokenAddress: "${token[
                  PolygonNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              }
              orderBy: timestamp
              orderDirection: desc
            ) {
              timestamp
              poolBalance
            }
          }
        `
      );
      return incentivePoolBalanceLogEntries;
    },
    {
      enabled: !!token[PolygonNetworkConfig.networkId]?.contractAddress,
    }
  );

  const { data: poolBalanceOnAvalancheData } = useQuery(
    [
      "poolBalanceOnAvalanche",
      token[AvalancheNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const { incentivePoolBalanceLogEntries } = await request(
        AvalancheNetworkConfig.subgraphEndpoint,
        gql`
          query {
            incentivePoolBalanceLogEntries(
              where: {
                tokenAddress: "${token[
                  AvalancheNetworkConfig.networkId
                ]?.contractAddress.toLowerCase()}"
              }
              orderBy: timestamp
              orderDirection: desc
            ) {
              timestamp
              poolBalance
            }
          }
        `
      );
      return incentivePoolBalanceLogEntries;
    },
    {
      enabled: !!token[AvalancheNetworkConfig.networkId]?.contractAddress,
    }
  );

  const poolBalanceOnEthereum = poolBalanceOnEthereumData
    ? poolBalanceOnEthereumData
        .slice(0, 20)
        .reverse()
        .map(
          (
            {
              poolBalance,
            }: {
              poolBalance: string;
            },
            index: {
              index: number;
            }
          ) => {
            return {
              x: index,
              y: ethers.utils.formatUnits(
                poolBalance,
                token[EthereumNetworkConfig.networkId]?.decimals
              ),
            };
          }
        )
    : [];

  const poolBalanceOnPolygon = poolBalanceOnPolygonData
    ? poolBalanceOnPolygonData
        .slice(0, 20)
        .reverse()
        .map(
          (
            {
              poolBalance,
            }: {
              poolBalance: string;
            },
            index: {
              index: number;
            }
          ) => {
            return {
              x: index,
              y: ethers.utils.formatUnits(
                poolBalance,
                token[AvalancheNetworkConfig.networkId]?.decimals
              ),
            };
          }
        )
    : [];

  const poolBalanceOnAvalanche = poolBalanceOnAvalancheData
    ? poolBalanceOnAvalancheData
        .slice(0, 20)
        .reverse()
        .map(
          (
            {
              poolBalance,
            }: {
              poolBalance: string;
            },
            index: { index: number }
          ) => {
            return {
              x: index,
              y: ethers.utils.formatUnits(
                poolBalance,
                token[EthereumNetworkConfig.networkId]?.decimals
              ),
            };
          }
        )
    : [];

  const graphData = [
    {
      id: "Ethereum",
      color: "hsl(344, 70%, 50%)",
      data: poolBalanceOnEthereum,
    },
    {
      id: "Polygon",
      color: "hsl(5, 70%, 50%)",
      data: poolBalanceOnPolygon,
    },
    {
      id: "Avalanche",
      color: "hsl(134, 70%, 50%)",
      data: poolBalanceOnAvalanche,
    },
  ];

  return (
    <RewardPoolBalanceGraphCard
      title={`Reward Pool Balance (${token.symbol})`}
      data={graphData}
    />
  );
}

function ExitFeeTableBody({ token }: { token: ITokenConfig }) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const epochModSecondsInADay = currentTimestamp % 86400;
  const todayEpoch = currentTimestamp - epochModSecondsInADay;
  const beginningEpoch = todayEpoch - 86400 * 1;

  const { data: tableDataOnEthereum } = useQuery(
    [
      "tableDataOnEthereum",
      token[EthereumNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      } = await request(
        EthereumNetworkConfig.subgraphEndpoint,
        gql`
          query {
            hourlyDeposits(where:{
                tokenAddress: "${
                  token[EthereumNetworkConfig.networkId]?.contractAddress
                }"
                timestamp_gt: ${beginningEpoch}
              },
              orderBy:timestamp,
              orderDirection:desc) {
              averageRewardAmountPercent
            }
            hourlyAssetSents(where:{
                tokenAddress: "${
                  token[EthereumNetworkConfig.networkId]?.contractAddress
                }"
                timestamp_gt: ${beginningEpoch}
              },
              orderBy:timestamp,
              orderDirection:desc) {
              averageLpFeePercent
              averageTransferFeePercent
              averageGasFeePercent
            }
            hourlySuppliedLiquidity(id: "${token[
              EthereumNetworkConfig.networkId
            ]?.contractAddress.toLowerCase()}") {
              suppliedLiquidity
              count
            }
            hourlyAvailableLiquidity(id: "${token[
              EthereumNetworkConfig.networkId
            ]?.contractAddress.toLowerCase()}") {
              availableLiquidity
              count
            }
          }
        `
      );
      return {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      };
    },
    {
      enabled: !!token[EthereumNetworkConfig.networkId]?.contractAddress,
    }
  );

  let dataOnEthereum: IExitFeeTableRow = {
    network: "Ethereum",
    tokenSymbol: token.symbol,
    averageAvailableLiquidity: 0,
    averageSuppliedLiquidity: 0,
    averageState: 0,
    averageGasFeePercent: 0,
    averageLpFeePercent: 0,
    averageRebalancingFeePercent: 0,
    averageRewardAmountPercent: 0,
  };
  if (tableDataOnEthereum) {
    const {
      hourlyDeposits,
      hourlyAssetSents,
      hourlySuppliedLiquidity,
      hourlyAvailableLiquidity,
    } = tableDataOnEthereum;
    const tokenDecimals = token[EthereumNetworkConfig.networkId].decimals;
    const { availableLiquidity, count: availableLiquidityCount } =
      hourlyAvailableLiquidity;
    const { suppliedLiquidity, count: suppliedLiquidityCount } =
      hourlySuppliedLiquidity;

    const averageAvailableLiquidity =
      Number.parseFloat(
        ethers.utils.formatUnits(availableLiquidity, tokenDecimals)
      ) / Number.parseFloat(availableLiquidityCount);
    const averageSuppliedLiquidity =
      Number.parseFloat(
        ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
      ) / Number.parseFloat(suppliedLiquidityCount);
    const averageState = averageAvailableLiquidity - averageSuppliedLiquidity;

    const { averageRewardAmountPercent: totalRewardAmountPercent } =
      hourlyDeposits.reduce(
        (acc: IAssetDeposit, currValue: IAssetDeposit) => {
          const { averageRewardAmountPercent } = currValue;
          const { averageRewardAmountPercent: prevAverageRewardAmountPercent } =
            acc;
          return {
            averageRewardAmountPercent:
              Number.parseFloat(prevAverageRewardAmountPercent) +
              Number.parseFloat(averageRewardAmountPercent),
          };
        },
        {
          averageRewardAmountPercent: 0,
        }
      );

    const {
      averageGasFeePercent: totalGasFeePercent,
      averageLpFeePercent: totalLpFeePercent,
      averageTransferFeePercent: totalTransferFeePercent,
    } = hourlyAssetSents.reduce(
      (acc: IAssetSentEntry, currValue: IAssetSentEntry) => {
        const {
          averageGasFeePercent,
          averageLpFeePercent,
          averageTransferFeePercent,
        } = currValue;
        const {
          averageGasFeePercent: prevGasFeePercent,
          averageLpFeePercent: prevLpFeePercent,
          averageTransferFeePercent: prevTransferFeePercent,
        } = acc;
        return {
          averageGasFeePercent:
            Number.parseFloat(prevGasFeePercent) +
            Number.parseFloat(averageGasFeePercent),
          averageLpFeePercent:
            Number.parseFloat(prevLpFeePercent) +
            Number.parseFloat(averageLpFeePercent),
          averageTransferFeePercent:
            Number.parseFloat(prevTransferFeePercent) +
            Number.parseFloat(averageTransferFeePercent),
        };
      },
      {
        averageGasFeePercent: 0,
        averageLpFeePercent: 0,
        averageTransferFeePercent: 0,
      }
    );

    const averageRewardAmountPercent = totalRewardAmountPercent
      ? totalRewardAmountPercent / hourlyDeposits.length
      : 0;
    const averageGasFeePercent = totalGasFeePercent
      ? totalGasFeePercent / hourlyAssetSents.length
      : 0;
    const averageLpFeePercent = totalLpFeePercent
      ? totalLpFeePercent / hourlyAssetSents.length
      : 0;
    const averageRebalancingFeePercent = totalTransferFeePercent
      ? totalTransferFeePercent / hourlyAssetSents.length - averageLpFeePercent
      : 0;

    dataOnEthereum = {
      network: "Ethereum",
      tokenSymbol: token.symbol,
      averageAvailableLiquidity,
      averageSuppliedLiquidity,
      averageState,
      averageGasFeePercent,
      averageLpFeePercent,
      averageRebalancingFeePercent,
      averageRewardAmountPercent,
    };
  }

  const { data: tableDataOnPolygon } = useQuery(
    [
      "tableDataOnPolygon",
      token[PolygonNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      } = await request(
        PolygonNetworkConfig.subgraphEndpoint,
        gql`
          query {
            hourlyDeposits(where:{
                tokenAddress: "${
                  token[PolygonNetworkConfig.networkId]?.contractAddress
                }"
                timestamp_gt: ${beginningEpoch}
              },
              orderBy:timestamp,
              orderDirection:desc) {
              averageRewardAmountPercent
            }
            hourlyAssetSents(where:{
                tokenAddress: "${
                  token[PolygonNetworkConfig.networkId]?.contractAddress
                }"
                timestamp_gt: ${beginningEpoch}
              },
              orderBy:timestamp,
              orderDirection:desc) {
              averageLpFeePercent
              averageTransferFeePercent
              averageGasFeePercent
            }
            hourlySuppliedLiquidity(id: "${token[
              PolygonNetworkConfig.networkId
            ]?.contractAddress.toLowerCase()}") {
              suppliedLiquidity
              count
            }
            hourlyAvailableLiquidity(id: "${token[
              PolygonNetworkConfig.networkId
            ]?.contractAddress.toLowerCase()}") {
              availableLiquidity
              count
            }
          }
        `
      );
      return {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      };
    },
    {
      enabled: !!token[PolygonNetworkConfig.networkId]?.contractAddress,
    }
  );

  let dataOnPolygon: IExitFeeTableRow = {
    network: "Polygon",
    tokenSymbol: token.symbol,
    averageAvailableLiquidity: 0,
    averageSuppliedLiquidity: 0,
    averageState: 0,
    averageGasFeePercent: 0,
    averageLpFeePercent: 0,
    averageRebalancingFeePercent: 0,
    averageRewardAmountPercent: 0,
  };
  if (tableDataOnPolygon) {
    const {
      hourlyDeposits,
      hourlyAssetSents,
      hourlySuppliedLiquidity,
      hourlyAvailableLiquidity,
    } = tableDataOnPolygon;
    const tokenDecimals = token[PolygonNetworkConfig.networkId].decimals;
    const { availableLiquidity, count: availableLiquidityCount } =
      hourlyAvailableLiquidity;
    const { suppliedLiquidity, count: suppliedLiquidityCount } =
      hourlySuppliedLiquidity;

    const averageAvailableLiquidity =
      Number.parseFloat(
        ethers.utils.formatUnits(availableLiquidity, tokenDecimals)
      ) / Number.parseFloat(availableLiquidityCount);
    const averageSuppliedLiquidity =
      Number.parseFloat(
        ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
      ) / Number.parseFloat(suppliedLiquidityCount);
    const averageState = averageAvailableLiquidity - averageSuppliedLiquidity;

    const { averageRewardAmountPercent: totalRewardAmountPercent } =
      hourlyDeposits.reduce(
        (acc: IAssetDeposit, currValue: IAssetDeposit) => {
          const { averageRewardAmountPercent } = currValue;
          const { averageRewardAmountPercent: prevAverageRewardAmountPercent } =
            acc;
          return {
            averageRewardAmountPercent:
              Number.parseFloat(prevAverageRewardAmountPercent) +
              Number.parseFloat(averageRewardAmountPercent),
          };
        },
        {
          averageRewardAmountPercent: 0,
        }
      );

    const {
      averageGasFeePercent: totalGasFeePercent,
      averageLpFeePercent: totalLpFeePercent,
      averageTransferFeePercent: totalTransferFeePercent,
    } = hourlyAssetSents.reduce(
      (acc: IAssetSentEntry, currValue: IAssetSentEntry) => {
        const {
          averageGasFeePercent,
          averageLpFeePercent,
          averageTransferFeePercent,
        } = currValue;
        const {
          averageGasFeePercent: prevGasFeePercent,
          averageLpFeePercent: prevLpFeePercent,
          averageTransferFeePercent: prevTransferFeePercent,
        } = acc;
        return {
          averageGasFeePercent:
            Number.parseFloat(prevGasFeePercent) +
            Number.parseFloat(averageGasFeePercent),
          averageLpFeePercent:
            Number.parseFloat(prevLpFeePercent) +
            Number.parseFloat(averageLpFeePercent),
          averageTransferFeePercent:
            Number.parseFloat(prevTransferFeePercent) +
            Number.parseFloat(averageTransferFeePercent),
        };
      },
      {
        averageGasFeePercent: 0,
        averageLpFeePercent: 0,
        averageTransferFeePercent: 0,
      }
    );

    const averageRewardAmountPercent = totalRewardAmountPercent
      ? totalRewardAmountPercent / hourlyDeposits.length
      : 0;
    const averageGasFeePercent = totalGasFeePercent
      ? totalGasFeePercent / hourlyAssetSents.length
      : 0;
    const averageLpFeePercent = totalLpFeePercent
      ? totalLpFeePercent / hourlyAssetSents.length
      : 0;
    const averageRebalancingFeePercent = totalTransferFeePercent
      ? totalTransferFeePercent / hourlyAssetSents.length - averageLpFeePercent
      : 0;

    dataOnPolygon = {
      network: "Polygon",
      tokenSymbol: token.symbol,
      averageAvailableLiquidity,
      averageSuppliedLiquidity,
      averageState,
      averageGasFeePercent,
      averageLpFeePercent,
      averageRebalancingFeePercent,
      averageRewardAmountPercent,
    };
  }

  const { data: tableDataOnAvalanche } = useQuery(
    [
      "tableDataOnAvalanche",
      token[AvalancheNetworkConfig.networkId]?.contractAddress,
    ],
    async () => {
      const {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      } = await request(
        AvalancheNetworkConfig.subgraphEndpoint,
        gql`
          query {
            hourlyDeposits(where:{
                tokenAddress: "${
                  token[AvalancheNetworkConfig.networkId]?.contractAddress
                }"
                timestamp_gt: ${beginningEpoch}
              },
              orderBy:timestamp,
              orderDirection:desc) {
              averageRewardAmountPercent
            }
            hourlyAssetSents(where:{
                tokenAddress: "${
                  token[AvalancheNetworkConfig.networkId]?.contractAddress
                }"
                timestamp_gt: ${beginningEpoch}
              },
              orderBy:timestamp,
              orderDirection:desc) {
              averageLpFeePercent
              averageTransferFeePercent
              averageGasFeePercent
            }
            hourlySuppliedLiquidity(id: "${token[
              AvalancheNetworkConfig.networkId
            ]?.contractAddress.toLowerCase()}") {
              suppliedLiquidity
              count
            }
            hourlyAvailableLiquidity(id: "${token[
              AvalancheNetworkConfig.networkId
            ]?.contractAddress.toLowerCase()}") {
              availableLiquidity
              count
            }
          }
        `
      );
      return {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      };
    },
    {
      enabled: !!token[AvalancheNetworkConfig.networkId]?.contractAddress,
    }
  );

  let dataOnAvalanche: IExitFeeTableRow = {
    network: "Avalanche",
    tokenSymbol: token.symbol,
    averageAvailableLiquidity: 0,
    averageSuppliedLiquidity: 0,
    averageState: 0,
    averageGasFeePercent: 0,
    averageLpFeePercent: 0,
    averageRebalancingFeePercent: 0,
    averageRewardAmountPercent: 0,
  };
  if (tableDataOnAvalanche) {
    const {
      hourlyDeposits,
      hourlyAssetSents,
      hourlySuppliedLiquidity,
      hourlyAvailableLiquidity,
    } = tableDataOnAvalanche;
    const tokenDecimals = token[AvalancheNetworkConfig.networkId].decimals;
    const { availableLiquidity, count: availableLiquidityCount } =
      hourlyAvailableLiquidity;
    const { suppliedLiquidity, count: suppliedLiquidityCount } =
      hourlySuppliedLiquidity;

    const averageAvailableLiquidity =
      Number.parseFloat(
        ethers.utils.formatUnits(availableLiquidity, tokenDecimals)
      ) / Number.parseFloat(availableLiquidityCount);
    const averageSuppliedLiquidity =
      Number.parseFloat(
        ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
      ) / Number.parseFloat(suppliedLiquidityCount);
    const averageState = averageAvailableLiquidity - averageSuppliedLiquidity;

    const { averageRewardAmountPercent: totalRewardAmountPercent } =
      hourlyDeposits.reduce(
        (acc: IAssetDeposit, currValue: IAssetDeposit) => {
          const { averageRewardAmountPercent } = currValue;
          const { averageRewardAmountPercent: prevAverageRewardAmountPercent } =
            acc;
          return {
            averageRewardAmountPercent:
              Number.parseFloat(prevAverageRewardAmountPercent) +
              Number.parseFloat(averageRewardAmountPercent),
          };
        },
        {
          averageRewardAmountPercent: 0,
        }
      );

    const {
      averageGasFeePercent: totalGasFeePercent,
      averageLpFeePercent: totalLpFeePercent,
      averageTransferFeePercent: totalTransferFeePercent,
    } = hourlyAssetSents.reduce(
      (acc: IAssetSentEntry, currValue: IAssetSentEntry) => {
        const {
          averageGasFeePercent,
          averageLpFeePercent,
          averageTransferFeePercent,
        } = currValue;
        const {
          averageGasFeePercent: prevGasFeePercent,
          averageLpFeePercent: prevLpFeePercent,
          averageTransferFeePercent: prevTransferFeePercent,
        } = acc;
        return {
          averageGasFeePercent:
            Number.parseFloat(prevGasFeePercent) +
            Number.parseFloat(averageGasFeePercent),
          averageLpFeePercent:
            Number.parseFloat(prevLpFeePercent) +
            Number.parseFloat(averageLpFeePercent),
          averageTransferFeePercent:
            Number.parseFloat(prevTransferFeePercent) +
            Number.parseFloat(averageTransferFeePercent),
        };
      },
      {
        averageGasFeePercent: 0,
        averageLpFeePercent: 0,
        averageTransferFeePercent: 0,
      }
    );

    const averageRewardAmountPercent = totalRewardAmountPercent
      ? totalRewardAmountPercent / hourlyDeposits.length
      : 0;
    const averageGasFeePercent = totalGasFeePercent
      ? totalGasFeePercent / hourlyAssetSents.length
      : 0;
    const averageLpFeePercent = totalLpFeePercent
      ? totalLpFeePercent / hourlyAssetSents.length
      : 0;
    const averageRebalancingFeePercent = totalTransferFeePercent
      ? totalTransferFeePercent / hourlyAssetSents.length - averageLpFeePercent
      : 0;

    dataOnAvalanche = {
      network: "Avalanche",
      tokenSymbol: token.symbol,
      averageAvailableLiquidity,
      averageSuppliedLiquidity,
      averageState,
      averageGasFeePercent,
      averageLpFeePercent,
      averageRebalancingFeePercent,
      averageRewardAmountPercent,
    };
  }

  return (
    <>
      {dataOnEthereum ? (
        <tr className="bg-white border-b">
          <td className="px-6 py-4">{token.symbol}</td>
          <td className="px-6 py-4">Ethereum</td>
          <td className="px-6 py-4">
            {Math.floor(
              dataOnEthereum.averageSuppliedLiquidity
            ).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {Math.floor(
              dataOnEthereum.averageAvailableLiquidity
            ).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {Math.floor(dataOnEthereum.averageState).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {dataOnEthereum.averageLpFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnEthereum.averageRebalancingFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnEthereum.averageRewardAmountPercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnEthereum.averageGasFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {(
              dataOnEthereum.averageLpFeePercent +
              dataOnEthereum.averageRebalancingFeePercent -
              dataOnEthereum.averageRewardAmountPercent +
              dataOnEthereum.averageGasFeePercent
            ).toFixed(3)}
            %
          </td>
        </tr>
      ) : null}

      {dataOnPolygon ? (
        <tr className="bg-white border-b">
          <td className="px-6 py-4">{token.symbol}</td>
          <td className="px-6 py-4">Polygon</td>
          <td className="px-6 py-4">
            {Math.floor(
              dataOnPolygon.averageSuppliedLiquidity
            ).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {Math.floor(
              dataOnPolygon.averageAvailableLiquidity
            ).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {Math.floor(dataOnPolygon.averageState).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {dataOnPolygon.averageLpFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnPolygon.averageRebalancingFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnPolygon.averageRewardAmountPercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnPolygon.averageGasFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {(
              dataOnPolygon.averageLpFeePercent +
              dataOnPolygon.averageRebalancingFeePercent -
              dataOnPolygon.averageRewardAmountPercent +
              dataOnPolygon.averageGasFeePercent
            ).toFixed(3)}
            %
          </td>
        </tr>
      ) : null}

      {dataOnAvalanche ? (
        <tr className="bg-white border-b">
          <td className="px-6 py-4">{token.symbol}</td>
          <td className="px-6 py-4">Avalanche</td>
          <td className="px-6 py-4">
            {Math.floor(
              dataOnAvalanche.averageSuppliedLiquidity
            ).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {Math.floor(
              dataOnAvalanche.averageAvailableLiquidity
            ).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {Math.floor(dataOnAvalanche.averageState).toLocaleString()}
          </td>
          <td className="px-6 py-4">
            {dataOnAvalanche.averageLpFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnAvalanche.averageRebalancingFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnAvalanche.averageRewardAmountPercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {dataOnAvalanche.averageGasFeePercent.toFixed(3)}%
          </td>
          <td className="px-6 py-4">
            {(
              dataOnAvalanche.averageLpFeePercent +
              dataOnAvalanche.averageRebalancingFeePercent -
              dataOnAvalanche.averageRewardAmountPercent +
              dataOnAvalanche.averageGasFeePercent
            ).toFixed(3)}
            %
          </td>
        </tr>
      ) : null}
    </>
  );
}

function RebalancingDashboard() {
  return (
    <div>
      {tokens.map((token, index) => {
        return (
          <SuppliedVsAvailableLiquidity
            token={token}
            key={`${token.symbol}-${index}`}
          />
        );
      })}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        {tokens.map((token, index) => {
          return (
            <RewardPoolBalance token={token} key={`${token.symbol}-${index}`} />
          );
        })}
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-4 grid grid-cols-1 gap-4">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Pool
              </th>
              <th scope="col" className="px-6 py-3">
                Chain
              </th>
              <th scope="col" className="px-6 py-3">
                Average Supplied Liquidity <br />
                (Last hour - AMT. in tokens)
              </th>
              <th scope="col" className="px-6 py-3">
                Average Available Liquidity <br />
                (Last hour - AMT. in tokens)
              </th>
              <th scope="col" className="px-6 py-3">
                Average State <br />
                (Last hour - AMT. in tokens)
              </th>
              <th scope="col" className="px-6 py-3">
                Average LP Fee <br />
                (Last 24 hours)
              </th>
              <th scope="col" className="px-6 py-3">
                Average Rebalancing Fee <br />
                (Last 24 hours)
              </th>
              <th scope="col" className="px-6 py-3">
                Average Rebalancing Reward <br />
                (Last 24 hours)
              </th>
              <th scope="col" className="px-6 py-3">
                Average Gas Fee <br />
                (Last 24 hours)
              </th>
              <th scope="col" className="px-6 py-3">
                Average Exit Fee <br />
                (Last 24 hours)
              </th>
            </tr>
          </thead>
          <tbody>
            {tokens.map((token, index) => {
              return (
                <ExitFeeTableBody
                  token={token}
                  key={`${token.symbol}-${index}`}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RebalancingDashboard;
