import { INetworkConfig } from "config/config";
import { useQuery, UseQueryOptions } from "react-query";
import { request, gql } from "graphql-request";

import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";

const Networks = [
  EthereumNetworkConfig,
  PolygonNetworkConfig,
  AvalancheNetworkConfig,
];

export default function useTransactionByDepositHashQuery(
  fromChainNetworkConfig: INetworkConfig,
  depositHash: string
  // options?: UseQueryOptions<string, Error, string, string[]>
) {
  return useQuery(
    [
      "transactionByDepositHash",
      fromChainNetworkConfig.networkName,
      depositHash,
    ],

    async () => {
      const subgraphDepositResponse = await request(
        fromChainNetworkConfig.subgraphEndpoint,
        gql`
          {
            deposit(id: "${depositHash}") {
              timestamp
              id
              toChainID
              rewardAmount
              amount
              sender
              tokenAddress
            }
          }
        `
      );

      if (!subgraphDepositResponse.deposit) {
        return undefined;
      }

      const toChainNetworkConfig: INetworkConfig = Networks.find(
        (network) =>
          network.networkId ===
          Number(subgraphDepositResponse.deposit.toChainID)
      )!;

      const subgraphExitResponse = await request(
        toChainNetworkConfig.subgraphEndpoint,
        gql`
            {
              assetSentToUserLogEntries(where: { depositHash: "${depositHash.toLowerCase()}" }) {
                id
                timestamp
                tokenAddress
                amount
                transferredAmount
                receiver
              }
            }
          `
      );

      if (
        !subgraphExitResponse.assetSentToUserLogEntries ||
        !subgraphExitResponse.assetSentToUserLogEntries[0]
      ) {
        return {
          from: subgraphDepositResponse.deposit,
        };
      }

      return {
        from: subgraphDepositResponse.deposit,
        to: subgraphExitResponse.assetSentToUserLogEntries[0],
      };
    },
    {
      // ...options,
    }
  );
}
