import * as React from "react";
import { twMerge } from "tailwind-merge";
import DataCard, { IDataCardProps } from "./DataCard";
import { IStyledDataCardProps } from "./types";
import EthereumLogo from "assets/images/ethereum.svg";
import { EthereumNetworkConfig } from "config/config";

const EthereumDataCard: React.FunctionComponent<IStyledDataCardProps> = (
  props
) => {
  return (
    <DataCard
      containerClassName="bg-gradient-to-bl from-ethereum to-black"
      titleClassName="text-white/80"
      bodyClassName="text-white"
      footerClassName="text-red-400"
      {...props}
    >
      <div className="absolute top-0 right-0 flex gap-1 items-center text-white text-xs pt-3 pr-3 font-medium">
        <img src={EthereumLogo} alt="Avalanche Logo" />{" "}
        {EthereumNetworkConfig.networkName}
      </div>
    </DataCard>
  );
};

export default EthereumDataCard;
