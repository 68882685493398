import DataCard from "components/DataCards/DataCard";
import Select, { Option } from "components/Inputs/Select";
import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import useLatestTransactionTimeQuery from "hooks/query/LatestTransactionTimeQuery";
import React, { useEffect, useMemo, useState } from "react";

interface ILastTransactionTimesProps {}

const Networks = [
  EthereumNetworkConfig,
  PolygonNetworkConfig,
  AvalancheNetworkConfig,
];

const NetworkOptions = Networks.map((networkConfig) => ({
  title: networkConfig.networkName,
  icon: networkConfig.networkIcon,
}));

const LastTransactionTimes: React.FC<ILastTransactionTimesProps> = (props) => {
  const [fromChain, setFromChain] = useState<Option>(NetworkOptions[0]);

  const toChainOptions = useMemo(
    () =>
      NetworkOptions.filter(
        (networkOption) => networkOption.title !== fromChain.title
      ),
    [fromChain]
  );

  const [toChain, setToChain] = useState<Option>(toChainOptions[0]);

  useEffect(() => {
    if (toChain.title === fromChain.title) {
      setToChain(toChainOptions[0]);
    }
  }, [fromChain, toChain, toChainOptions]);

  const lastTransactionTimeQuery = useLatestTransactionTimeQuery(
    Networks.find((network) => network.networkName === fromChain.title)!,
    Networks.find((network) => network.networkName === toChain.title)!
  );

  return (
    <DataCard
      title="Last Transaction Time"
      body={
        <div className="flex flex-col gap-4">
          <div className="text-xl">
            {lastTransactionTimeQuery.isLoading
              ? "..."
              : lastTransactionTimeQuery.data}
          </div>
          <div className="grid grid-cols-2 text-lg gap-4">
            <Select
              options={NetworkOptions}
              onChange={setFromChain}
              selected={fromChain}
            />
            <Select
              options={toChainOptions}
              onChange={setToChain}
              selected={toChain}
            />
          </div>
        </div>
      }
    />
  );
};

export default LastTransactionTimes;
