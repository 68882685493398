import { ResponsiveLine } from "@nivo/line";
import * as React from "react";

interface ISLVSALData {
  id: string;
  color: string;
  data: any;
}

interface ISLVsALGraphCardProps {
  data?: ISLVSALData[];
  title: string;
}

const SLVsALGraphCard: React.FunctionComponent<ISLVsALGraphCardProps> = ({
  data,
  title,
}) => {
  return (
    <div className="h-[400px] py-6 px-4 border border-black/20 rounded-md flex flex-col">
      <h2 className="text-lg">{title}</h2>
      {data ? (
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
          xScale={{ type: "point" }}
          yScale={{
            type: "linear",
            min: "auto",
            max: "auto",
            stacked: false,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={null}
          axisLeft={{
            tickSize: 0,
            tickPadding: 4,
            tickRotation: 0,
            legend: "Liquidity (in tokens)",
            legendOffset: -54,
            legendPosition: "middle",
          }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabelYOffset={-12}
          useMesh={true}
          legends={[
            {
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 100,
              translateY: 0,
              itemsSpacing: 0,
              itemDirection: "left-to-right",
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 0.75,
              symbolSize: 12,
              symbolShape: "circle",
              symbolBorderColor: "rgba(0, 0, 0, .5)",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemBackground: "rgba(0, 0, 0, .03)",
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default SLVsALGraphCard;
