import { getNetworkTokens, INetworkConfig } from "config/config";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { getCurrentFiatRates, getHistoricalFiatRates } from "./utils";
import { ethers } from "ethers";
import { coingeckoTokenIdMap } from "./config";

export type NetworkDailyVolume = {
  tokenAddress: string;
  cumulativeAmount: number;
  timestamp: string;
};

export type NetworkDailyFiatVolume = NetworkDailyVolume & {
  cumulativeFiatAmount: number;
};

export default function useNetworkDailyVolumeQuery(
  networkConfig: INetworkConfig
) {
  return useQuery<NetworkDailyVolume[]>(
    ["dailyVolume", networkConfig.networkName],
    async () => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const epochModSecondsInADay = currentTimestamp % 86400;
      const todayEpoch = currentTimestamp - epochModSecondsInADay;
      const beginningEpoch = todayEpoch - 86400 * 10;

      const subgraphResponse = await request(
        networkConfig.subgraphEndpoint,
        gql`
          {
            dailyDepositVolumePerChainAndTokens(
              orderBy: timestamp
              orderDirection: desc
              where: { 
                timestamp_gt: ${beginningEpoch}
              }
            ) {
              tokenAddress
              cumulativeAmount
              timestamp
            }
          }
        `
      );

      const deduplicatedNetworkDailyVolumes = (
        subgraphResponse.dailyDepositVolumePerChainAndTokens as Array<NetworkDailyVolume>
      ).reduce((acc, curr) => {
        const key = `${curr.tokenAddress}-${curr.timestamp}`;
        curr.cumulativeAmount = parseFloat(
          parseFloat(
            ethers.utils.formatUnits(
              curr.cumulativeAmount,
              getNetworkTokens(networkConfig.networkId).find(
                (token) =>
                  token.contractAddress.toLowerCase() ===
                  curr.tokenAddress.toLowerCase()
              )?.decimals
            )
          ).toFixed(3)
        );
        if (acc[key]) {
          acc[key].cumulativeAmount += curr.cumulativeAmount;
        } else {
          acc[key] = curr;
        }
        return acc;
      }, {} as { [key: string]: NetworkDailyVolume });

      const networkDailyVolumes = Object.values(
        deduplicatedNetworkDailyVolumes
      );

      return networkDailyVolumes;
    },
    {
      staleTime: 60000,
    }
  );
}
