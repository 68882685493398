import React from "react";
import AvalancheDataCard from "components/DataCards/AvalancheDataCard";
import EthereumDataCard from "components/DataCards/EthereumDataCard";
import PolygonDataCard from "components/DataCards/PolygonDataCard";
import DataCard from "components/DataCards/DataCard";
import EthereumUniqueUsers from "components/Ethereum/EthereumUniqueUsers";
import PolygonUniqueUsers from "components/Polygon/PolygonUniqueUsers";
import AvalancheUniqueUsers from "components/Avalanche/AvalancheUniqueUsers";
import Ticker from "components/Ticker";
import OverallUniqueUsers from "components/Overall/OverallUniqueUsers";

import EthereumDayVolume from "components/Ethereum/EthereumDayVolume";
import PolygonDayVolume from "components/Polygon/PolygonDayVolume";
import AvalancheDayVolume from "components/Avalanche/AvalancheDayVolume";

import EthereumLiquidityExceptEthereum from "components/Ethereum/EthereumLiquidityExceptEthereum";
import EthereumLiquidityEthereum from "components/Ethereum/EthereumLiquidityEthereum";

import AvalancheLiquidityExceptEthereum from "components/Avalanche/AvalancheLiquidityExceptEthereum";
import AvalancheLiquidityEthereum from "components/Avalanche/AvalancheLiquidityEthereum";

import PolygonLiquidityExceptEthereum from "components/Polygon/PolygonLiquidityExceptEthereum";
import PolygonLiquidityEthereum from "components/Polygon/PolygonLiquidityEthereum";
import OverallDailyVolume from "components/Overall/OverallDailyVolume";
import LastTransactionTimes from "components/Overall/LastTransactionTimes";
import Topbar from "components/Topbar";
import PolygonTotalVolume from "components/Polygon/PolygonTotalVolume";
import OverallDayVolume from "components/Overall/OverallDayVolume";
import LpFeeGraph from "components/Overall/LpFeeGraph";
import EthereumTotalVolume from "components/Ethereum/EthereumTotalVolume";
import AvalancheTotalVolume from "components/Avalanche/AvalancheTotalVolume";
import OverallTotalVolume from "components/Overall/OverallTotalVolume";
import EthereumFiatLiquidity from "components/Ethereum/EthereumFiatLiquidity";
import PolygonFiatLiquidity from "components/Polygon/PolygonFiatLiquidity";
import AvalancheFiatLiquidity from "components/Avalanche/AvalancheFiatLiquidity";

function Home() {
  return (
    <>
      <Topbar />
      <div className="grid md:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-4">
        <OverallDayVolume />
        <OverallTotalVolume />
        <LastTransactionTimes></LastTransactionTimes>
        <OverallUniqueUsers />
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <PolygonDayVolume />
        <PolygonTotalVolume />
        <PolygonUniqueUsers />
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <EthereumDayVolume />
        <EthereumTotalVolume />
        <EthereumUniqueUsers />
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
        <AvalancheDayVolume />
        <AvalancheTotalVolume />
        <AvalancheUniqueUsers />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <OverallDailyVolume />
        <LpFeeGraph />
      </div>
      <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 pb-8">
        <EthereumLiquidityExceptEthereum />
        <PolygonLiquidityExceptEthereum />
        <AvalancheLiquidityExceptEthereum />
        <EthereumLiquidityEthereum />
        <PolygonLiquidityEthereum />
        <AvalancheLiquidityEthereum />
        <EthereumFiatLiquidity />
        <PolygonFiatLiquidity />
        <AvalancheFiatLiquidity />
      </div>
    </>
  );
}

export default Home;
