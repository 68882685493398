import DataCard from "components/DataCards/DataCard";
import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import useNetworkTotalFiatVolumeQuery, {
  NetworkTotalFiatVolume,
} from "hooks/query/volume/NetworkTotalFiatVolumeQuery";
import React, { useMemo } from "react";

const OverallTotalVolume: React.FC = ({}) => {
  const polygonDayVolumeQuery =
    useNetworkTotalFiatVolumeQuery(PolygonNetworkConfig);

  const avalancheDayVolumeQuery = useNetworkTotalFiatVolumeQuery(
    AvalancheNetworkConfig
  );
  const ethereumDayVolumeQuery = useNetworkTotalFiatVolumeQuery(
    EthereumNetworkConfig
  );

  // console.log(polygonDayVolumeQuery.data);

  const dayVolume = useMemo(() => {
    if (
      !polygonDayVolumeQuery.data ||
      !avalancheDayVolumeQuery.data ||
      !ethereumDayVolumeQuery.data
    )
      return undefined;

    const polygonDayVolumeFloat = (
      polygonDayVolumeQuery.data as NetworkTotalFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    const ethereumDayVolumeFloat = (
      ethereumDayVolumeQuery.data as NetworkTotalFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    const avalancheDayVolumeFloat = (
      avalancheDayVolumeQuery.data as NetworkTotalFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    const overallDayVolumeFiat =
      polygonDayVolumeFloat + ethereumDayVolumeFloat + avalancheDayVolumeFloat;

    return (
      "$ " + parseInt(overallDayVolumeFiat.toString()).toLocaleString("en-US")
    );
  }, [
    polygonDayVolumeQuery.data,
    avalancheDayVolumeQuery.data,
    ethereumDayVolumeQuery.data,
  ]);

  return <DataCard title="Total Volume" body={dayVolume || "..."} />;
};

export default OverallTotalVolume;
