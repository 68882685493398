import {
  getNetworkTokens,
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from "config/config";
import { useQueries, useQuery } from "react-query";
import { request, gql } from "graphql-request";
import {
  CoinGeckoHistoricalPrice,
  getCurrentFiatRates,
  getHistoricalFiatRates,
} from "./utils";
import { ethers } from "ethers";
import { coingeckoTokenIdMap } from "./config";
import useNetworkDailyVolumeQuery from "./NetworkDailyVolumeQuery";
import { useMemo } from "react";
import axios from "axios";

export type NetworkDailyVolume = {
  tokenAddress: string;
  cumulativeAmount: number;
  timestamp: string;
};

export type NetworkDailyFiatVolume = NetworkDailyVolume & {
  cumulativeFiatAmount: number;
};

export default function useNetworkDailyFiatVolumeQuery(
  networkConfig: INetworkConfig
) {
  const networkDailyVolumeQuery = useNetworkDailyVolumeQuery(networkConfig);

  const tokenAddressTimestampPairs = useMemo(() => {
    if (!networkDailyVolumeQuery.data) return undefined;
    const networkDailyVolumes = networkDailyVolumeQuery.data;
    const uniqueTokenAddressTimestamps: { [tokenAddress: string]: string[] } =
      {};

    for (const networkDailyVolume of networkDailyVolumes) {
      uniqueTokenAddressTimestamps[networkDailyVolume.tokenAddress] = [
        ...(uniqueTokenAddressTimestamps[networkDailyVolume.tokenAddress] ||
          []),
        networkDailyVolume.timestamp,
      ];
    }

    const tokenAddressTimestampPairs: [
      tokenAddress: string,
      timestamp: string
    ][] = [];

    for (const [tokenAddress, timestamps] of Object.entries(
      uniqueTokenAddressTimestamps
    )) {
      for (const timestamp of timestamps) {
        tokenAddressTimestampPairs.push([tokenAddress, timestamp]);
      }
    }

    return tokenAddressTimestampPairs;
  }, [networkDailyVolumeQuery.data]);

  const uniqueTokenAddresses = useMemo(() => {
    const networkDailyVolumes = networkDailyVolumeQuery.data;
    if (!networkDailyVolumes) return undefined;

    const uniqueTokenAddresses = new Set<string>();

    for (const networkDailyVolume of networkDailyVolumes) {
      uniqueTokenAddresses.add(networkDailyVolume.tokenAddress);
    }

    return Array.from(uniqueTokenAddresses);
  }, [networkDailyVolumeQuery.data]);

  const tokenIdToContractAddressMapping = useMemo(() => {
    if (!uniqueTokenAddresses) return undefined;
    return getTokenIdToContractAddressMapping(
      uniqueTokenAddresses,
      networkConfig
    );
  }, [uniqueTokenAddresses, networkConfig]);

  const tokenFiatPricesQuery = useQueries(
    tokenAddressTimestampPairs && tokenIdToContractAddressMapping
      ? tokenAddressTimestampPairs.map(([tokenAddress, timestamp]) => ({
          queryKey: ["tokenPriceAtTime", tokenAddress, timestamp],
          queryFn: async () => {
            let date = new Date(0);
            date.setUTCSeconds(parseInt(timestamp));
            var dd = String(date.getDate()).padStart(2, "0");
            var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = date.getFullYear();
            let dateString = `${dd}-${mm}-${yyyy}`;
            const tokenId = tokenIdToContractAddressMapping[tokenAddress];

            const rawRate = await axios.get(
              `https://api.coingecko.com/api/v3/coins/${tokenId}/history`,
              {
                params: {
                  date: dateString,
                  localization: "false",
                },
              }
            );
            let priceInUSD: CoinGeckoHistoricalPrice = rawRate.data;
            // console.log(priceInUSD.market_data.current_price.usd);
            return {
              fiatPrice: priceInUSD.market_data.current_price.usd,
              tokenAddress,
              timestamp,
            };
          },
          staleTime: 3600000, // 1 hour
        }))
      : []
  );

  return useQuery<NetworkDailyFiatVolume[] | undefined>(
    ["dailyFiatVolume", networkConfig.networkName],
    async () => {
      const networkDailyVolumes = networkDailyVolumeQuery.data;
      if (!networkDailyVolumes) return undefined;

      const tokenFiatPriceTimestampMap: {
        [tokenAddress: string]: {
          [timestamp: string]: string;
        };
      } = {};

      const fiatPricesQueryData = tokenFiatPricesQuery.map((q) => q.data);

      for (const fiatPricesQueryDatum of fiatPricesQueryData) {
        const { fiatPrice, tokenAddress, timestamp } = fiatPricesQueryDatum!;
        if (!tokenFiatPriceTimestampMap[tokenAddress]) {
          tokenFiatPriceTimestampMap[tokenAddress] = {};
        }

        tokenFiatPriceTimestampMap[tokenAddress] = {
          ...tokenFiatPriceTimestampMap[tokenAddress],
          [timestamp]: fiatPrice,
        };
      }

      let networkDailyFiatVolumes: NetworkDailyFiatVolume[] = [];
      for (const networkDailyVolume of networkDailyVolumes) {
        // console.log(`Fiat amount for ${networkDailyVolume.tokenAddress} is ${fiatAmount}`);
        networkDailyFiatVolumes.push({
          ...networkDailyVolume,
          cumulativeFiatAmount:
            networkDailyVolume.cumulativeAmount *
            parseFloat(
              tokenFiatPriceTimestampMap[networkDailyVolume.tokenAddress][
                networkDailyVolume.timestamp
              ]
            ),
        });
      }
      return networkDailyFiatVolumes;
    },
    {
      staleTime: 60000,
      enabled:
        !!networkDailyVolumeQuery.data &&
        !!!tokenFiatPricesQuery.some(
          (queryResult) => queryResult.data === undefined
        ) &&
        tokenFiatPricesQuery.length > 0,
    }
  );
}
