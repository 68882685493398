import React from "react";

import EthereumDataCard from "components/DataCards/EthereumDataCard";
import { EthereumNetworkConfig } from "config/config";
import useNetworkUniqueUsersQuery from "hooks/query/uniqueUsers/NetworkUniqueUsersQuery";

const EthereumUniqueUsers: React.FC = (props) => {
  const ethereumUniqueUsersQuery = useNetworkUniqueUsersQuery(EthereumNetworkConfig);

  return (
    <EthereumDataCard
      title="Unique Users"
      body={ethereumUniqueUsersQuery.data || "..."}
    />
  );
};

export default EthereumUniqueUsers;
