import AvalancheDataCard from "components/DataCards/AvalancheDataCard";
import { AvalancheNetworkConfig } from "config/config";
import useNetworkDayFiatVolumeQuery from "hooks/query/volume/NetworkDayFiatVolumeQuery";
import useNetworkDayVolumeQuery, {
  NetworkDayFiatVolume,
  NetworkDayVolume,
} from "hooks/query/volume/NetworkDayVolumeQuery";
import React, { useMemo } from "react";

const AvalancheDayVolume: React.FC = ({}) => {
  const avalancheDayVolumeQuery = useNetworkDayFiatVolumeQuery(
    AvalancheNetworkConfig
  );

  // console.log(avalancheDayVolumeQuery.data);

  const dayVolume = useMemo(() => {
    if (!avalancheDayVolumeQuery.data) return undefined;

    const dayVolumeFloat = (
      avalancheDayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    return "$ " + parseInt(dayVolumeFloat.toString()).toLocaleString("en-US");
  }, [avalancheDayVolumeQuery.data]);

  return <AvalancheDataCard title="24 Hour Volume" body={dayVolume || "..."} />;
};

export default AvalancheDayVolume;
