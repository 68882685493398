import DataCard from "components/DataCards/DataCard";
import PolygonDataCard from "components/DataCards/PolygonDataCard";
import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import useNetworkDayFiatVolumeQuery from "hooks/query/volume/NetworkDayFiatVolumeQuery";
import useNetworkDayVolumeQuery, {
  NetworkDayFiatVolume,
  NetworkDayVolume,
} from "hooks/query/volume/NetworkDayVolumeQuery";
import React, { useMemo } from "react";

const OverallDayVolume: React.FC = ({}) => {
  const polygonDayVolumeQuery =
    useNetworkDayFiatVolumeQuery(PolygonNetworkConfig);

  const avalancheDayVolumeQuery = useNetworkDayFiatVolumeQuery(
    AvalancheNetworkConfig
  );
  const ethereumDayVolumeQuery = useNetworkDayFiatVolumeQuery(
    EthereumNetworkConfig
  );

  // console.log(polygonDayVolumeQuery.data);

  const dayVolume = useMemo(() => {
    if (
      !polygonDayVolumeQuery.data ||
      !avalancheDayVolumeQuery.data ||
      !ethereumDayVolumeQuery.data
    )
      return undefined;

    const polygonDayVolumeFloat = (
      polygonDayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    const ethereumDayVolumeFloat = (
      ethereumDayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    const avalancheDayVolumeFloat = (
      avalancheDayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    const overallDayVolumeFiat =
      polygonDayVolumeFloat + ethereumDayVolumeFloat + avalancheDayVolumeFloat;

    return (
      "$ " + parseInt(overallDayVolumeFiat.toString()).toLocaleString("en-US")
    );
  }, [
    polygonDayVolumeQuery.data,
    avalancheDayVolumeQuery.data,
    ethereumDayVolumeQuery.data,
  ]);

  return <DataCard title="24 Hour Volume" body={dayVolume || "..."} />;
};

export default OverallDayVolume;
