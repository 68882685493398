import React, { useMemo } from "react";

import EthereumDataCard from "components/DataCards/EthereumDataCard";
import { EthereumNetworkConfig, PolygonNetworkConfig } from "config/config";
import { UseQueryResult } from "react-query";
import { ResponsiveBar } from "@nivo/bar";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import FiatLiquidityGraphCard from "components/FiatLiquidityGraphCard";
import useNetworkTokensFiatLiquidityQuery from "hooks/query/liquidity/NetworkTokensFiatLiquidityQuery";

const EthereumFiatLiquidity: React.FC = (props) => {
  const fiatLiquidity = useNetworkTokensFiatLiquidityQuery(
    EthereumNetworkConfig
  );

  return (
    <FiatLiquidityGraphCard
      data={fiatLiquidity.data}
      title={`${EthereumNetworkConfig.networkName} Liquidity (USD)`}
    />
  );
};

export default EthereumFiatLiquidity;
