import { ResponsiveBar } from "@nivo/bar";
import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import * as React from "react";
import { format } from "date-fns";
import useNetworkDailyFiatFeeQuery, {
  NetworkDailyFiatFee,
} from "hooks/query/fee/NetworkDailyFiatFeeQuery";

const OverallDailyLpFee: React.FunctionComponent = (props) => {
  const ethereumDailyFee = useNetworkDailyFiatFeeQuery(EthereumNetworkConfig);
  const polygonDailyFee = useNetworkDailyFiatFeeQuery(PolygonNetworkConfig);
  const avalancheDailyFee = useNetworkDailyFiatFeeQuery(AvalancheNetworkConfig);

  const data = {} as {
    [key: string]: {
      [key: string]: any;
    };
  };

  if (ethereumDailyFee.data && polygonDailyFee.data && avalancheDailyFee.data) {
    console.log(
      ethereumDailyFee.data,
      polygonDailyFee.data,
      avalancheDailyFee.data
    );
    for (const ethereumDayLpFee of ethereumDailyFee.data as NetworkDailyFiatFee[]) {
      const key = ethereumDayLpFee.timestamp;
      let currentValue = data[key];
      if (currentValue && data[key].ethereumDayLpFee) {
        data[key].ethereumDayLpFee += ethereumDayLpFee.cumulativeFiatLpFee;
      } else {
        data[key] = {
          ...data[key],
          timestamp: ethereumDayLpFee.timestamp,
          ethereumDayLpFee: ethereumDayLpFee.cumulativeFiatLpFee,
        };
      }
    }

    for (const polygonDayLpFee of polygonDailyFee.data as NetworkDailyFiatFee[]) {
      const key = polygonDayLpFee.timestamp;
      let currentValue = data[key];
      if (currentValue && data[key].polygonDayLpFee) {
        data[key].polygonDayLpFee += polygonDayLpFee.cumulativeFiatLpFee;
      } else {
        data[key] = {
          ...data[key],
          timestamp: polygonDayLpFee.timestamp,
          polygonDayLpFee: polygonDayLpFee.cumulativeFiatLpFee,
        };
      }
    }

    for (const avalancheDayLpFee of avalancheDailyFee.data as NetworkDailyFiatFee[]) {
      const key = avalancheDayLpFee.timestamp;
      let currentValue = data[key];
      if (currentValue && data[key].avalancheDayLpFee) {
        data[key].avalancheDayLpFee += avalancheDayLpFee.cumulativeFiatLpFee;
      } else {
        data[key] = {
          ...data[key],
          timestamp: avalancheDayLpFee.timestamp,
          avalancheDayLpFee: avalancheDayLpFee.cumulativeFiatLpFee,
        };
      }
    }
  }

  const processedData = Object.values(data) as any;
  console.log(processedData);
  return (
    <div className="h-[400px] py-6 px-4 border border-black/20 rounded-md flex flex-col">
      <div className="text-lg">Daily LP Fee (USD)</div>{" "}
      {!processedData || processedData.length === 0 ? (
        "..."
      ) : (
        <ResponsiveBar
          data={processedData.map((d: any) => ({
            ...d,
            Ethereum: d.ethereumDayLpFee || 0,
            Polygon: d.polygonDayLpFee || 0,
            Avalanche: d.avalancheDayLpFee || 0,
            timestamp: format(
              new Date(parseInt(d.timestamp as string) * 1000),
              "dd/MM"
            ),
          }))}
          keys={["Ethereum", "Polygon", "Avalanche"]}
          indexBy="timestamp"
          margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
          padding={0.3}
          groupMode="stacked"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          valueFormat={(n) =>
            Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 2,
            }).format(n)
          }
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Token",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Lp Fee",
            legendPosition: "middle",
            legendOffset: -40,
            format: (n) =>
              Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 2,
              }).format(n),
          }}
          labelSkipWidth={36}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="LP Fee Chart"
        />
      )}
    </div>
  );
};

export default OverallDailyLpFee;
