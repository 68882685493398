import * as React from "react";
import { twMerge } from "tailwind-merge";
import DataCard, { IDataCardProps } from "./DataCard";
import { IStyledDataCardProps } from "./types";
import AvalancheLogo from "assets/images/avalanche.svg";
import { AvalancheNetworkConfig } from "config/config";

const AvalancheDataCard: React.FunctionComponent<IStyledDataCardProps> = (
  props
) => {
  return (
    <DataCard
      containerClassName="bg-gradient-to-bl from-avalanche to-black"
      titleClassName="text-white/80"
      bodyClassName="text-white"
      footerClassName="text-red-400"
      {...props}
    >
      <div className="absolute top-0 right-0 flex gap-1 items-center text-white text-xs pt-3 pr-3 font-medium">
        <img src={AvalancheLogo} alt="Avalanche Logo" />
        {AvalancheNetworkConfig.networkName}
      </div>
    </DataCard>
  );
};

export default AvalancheDataCard;
