import React, { useMemo } from "react";

import AvalancheDataCard from "components/DataCards/AvalancheDataCard";
import { AvalancheNetworkConfig, PolygonNetworkConfig } from "config/config";
import { UseQueryResult } from "react-query";
import { ResponsiveBar } from "@nivo/bar";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import LiquidityGraphCard from "components/LiquidityGraphCard";

const AvalancheLiquidityExceptEthereum: React.FC = (props) => {
  const avalancheLiquidity = useNetworkTokensLiquidityQuery(
    AvalancheNetworkConfig
  );

  return (
    <LiquidityGraphCard
      data={avalancheLiquidity?.data?.filter(
        (tokenLiquidity) => tokenLiquidity.tokenSymbol !== "ETH"
      )}
      title={`${AvalancheNetworkConfig.networkName} Liquidity`}
    />
  );
};

export default AvalancheLiquidityExceptEthereum;
