import {
  getNetworkTokens,
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from "config/config";
import { useQueries, useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { getCurrentFiatRates } from "./utils";
import { ethers } from "ethers";
import useNetworkDayVolumeQuery from "./NetworkDayVolumeQuery";
import { useMemo } from "react";
import axios from "axios";

export type NetworkDayVolume = {
  tokenAddress: string;
  cumulativeAmount: number;
};

export type NetworkDayFiatVolume = NetworkDayVolume & {
  cumulativeFiatAmount: number;
};

export default function useNetworkDayFiatVolumeQuery(
  networkConfig: INetworkConfig
) {
  const networkDayVolumeQuery = useNetworkDayVolumeQuery(networkConfig);
  const networkDayVolumes = networkDayVolumeQuery.data;

  const uniqueTokenAddresses = useMemo(() => {
    if (!networkDayVolumes) return undefined;
    const uniqueTokenAddresses = new Set<string>();

    for (const networkDayVolume of networkDayVolumes) {
      uniqueTokenAddresses.add(networkDayVolume.tokenAddress);
    }

    return Array.from(uniqueTokenAddresses);
  }, [networkDayVolumes]);

  const tokenFiatPriceQuery = useQuery(
    ["tokensPriceCurrent", uniqueTokenAddresses],
    async () => {
      if (!uniqueTokenAddresses) return undefined;
      const tokenIdToContractAddressMapping =
        getTokenIdToContractAddressMapping(uniqueTokenAddresses, networkConfig);
      const rawRates = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price",
        {
          params: {
            ids: Object.values(tokenIdToContractAddressMapping).join(","),
            vs_currencies: "USD",
          },
        }
      );

      const tokenRates: { [tokenAddress: string]: number } = {};
      const rates = rawRates.data as { [tokenId: string]: { usd: number } };
      for (const [tokenId, { usd }] of Object.entries(rates)) {
        tokenRates[tokenIdToContractAddressMapping[tokenId]] = parseFloat(
          usd as unknown as string
        );
      }

      return tokenRates;
    },
    {
      enabled: !!uniqueTokenAddresses,
      staleTime: 3600000, // 1 hour
    }
  );

  return useQuery<undefined | NetworkDayFiatVolume[]>(
    ["dayFiatVolume", networkConfig.networkName],
    async () => {
      if (!networkDayVolumes || !tokenFiatPriceQuery.data) {
        return undefined;
      }

      const tokenRates = tokenFiatPriceQuery.data;

      const networkDayFiatVolumes = networkDayVolumes.map(
        (networkDayVolume) => ({
          ...networkDayVolume,
          cumulativeFiatAmount:
            networkDayVolume.cumulativeAmount *
            tokenRates[networkDayVolume.tokenAddress],
        })
      );
      return networkDayFiatVolumes as NetworkDayFiatVolume[];
    },
    {
      staleTime: 60000,
      enabled: !!networkDayVolumes && !!tokenFiatPriceQuery.data,
    }
  );
}
