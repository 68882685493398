import {
  getNetworkTokens,
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from "config/config";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { getCurrentFiatRates } from "./utils";
import { ethers } from "ethers";

export type NetworkTotalVolume = {
  tokenAddress: string;
  cumulativeAmount: number;
};

export default function useNetworkTotalVolumeQuery(
  networkConfig: INetworkConfig
) {
  return useQuery<NetworkTotalVolume[]>(
    ["cumulativeVolume", networkConfig.networkName],
    async () => {
      const subgraphResponse = await request(
        networkConfig.subgraphEndpoint,
        gql`
          query {
            depositVolumeCumulativePerChainAndTokens {
              cumulativeAmount
              tokenAddress
            }
          }
        `
      );
      console.log(JSON.stringify(subgraphResponse));
      const deduplicatedNetworkCumulativeVolumes = (
        subgraphResponse.depositVolumeCumulativePerChainAndTokens as Array<NetworkTotalVolume>
      ).reduce((acc, curr) => {
        curr.cumulativeAmount = parseFloat(
          parseFloat(
            ethers.utils.formatUnits(
              curr.cumulativeAmount,
              getNetworkTokens(networkConfig.networkId).find(
                (token) =>
                  token.contractAddress.toLowerCase() ===
                  curr.tokenAddress.toLowerCase()
              )!.decimals
            )
          ).toFixed(3)
        );
        acc[curr.tokenAddress] = {
          tokenAddress: curr.tokenAddress,
          cumulativeAmount:
            (acc[curr.tokenAddress]?.cumulativeAmount || 0) +
            curr.cumulativeAmount,
        };
        return acc;
      }, {} as { [tokenAddress: string]: NetworkTotalVolume });

      console.log(deduplicatedNetworkCumulativeVolumes);

      const networkCumulativeVolumes = Object.values(
        deduplicatedNetworkCumulativeVolumes
      );

      return networkCumulativeVolumes;
    },
    {
      staleTime: 60000,
    }
  );
}
