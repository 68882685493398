import React, { useMemo } from "react";

import AvalancheDataCard from "components/DataCards/AvalancheDataCard";
import { AvalancheNetworkConfig, PolygonNetworkConfig } from "config/config";
import { UseQueryResult } from "react-query";
import { ResponsiveBar } from "@nivo/bar";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import FiatLiquidityGraphCard from "components/FiatLiquidityGraphCard";
import useNetworkTokensFiatLiquidityQuery from "hooks/query/liquidity/NetworkTokensFiatLiquidityQuery";

const AvalancheFiatLiquidity: React.FC = (props) => {
  const fiatLiquidity = useNetworkTokensFiatLiquidityQuery(
    AvalancheNetworkConfig
  );

  return (
    <FiatLiquidityGraphCard
      data={fiatLiquidity.data}
      title={`${AvalancheNetworkConfig.networkName} Liquidity (USD)`}
    />
  );
};

export default AvalancheFiatLiquidity;
