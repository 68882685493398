import PolygonDataCard from "components/DataCards/PolygonDataCard";
import { PolygonNetworkConfig } from "config/config";
import useNetworkTotalFiatVolumeQuery, {
  NetworkTotalFiatVolume,
} from "hooks/query/volume/NetworkTotalFiatVolumeQuery";
import React, { useMemo } from "react";

interface IPolygonTotalVolumeProps {}

const PolygonTotalVolume: React.FunctionComponent<IPolygonTotalVolumeProps> = (
  props
) => {
  const polygonTotalVolumeQuery =
    useNetworkTotalFiatVolumeQuery(PolygonNetworkConfig);

  // console.log(polygonDayVolumeQuery.data);

  const cumulativeVolume = useMemo(() => {
    if (!polygonTotalVolumeQuery.data) return undefined;

    return (polygonTotalVolumeQuery.data as NetworkTotalFiatVolume[]).reduce(
      (acc, curr) => {
        return acc + curr.cumulativeFiatAmount || 0;
      },
      0
    );
  }, [polygonTotalVolumeQuery.data]);

  return (
    <PolygonDataCard
      title="Total Volume"
      body={
        cumulativeVolume
          ? `$ ${Number(cumulativeVolume.toFixed(0)).toLocaleString("en-US")}`
          : "..."
      }
    />
  );
};

export default PolygonTotalVolume;
