import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import { useQuery, UseQueryOptions } from "react-query";
import useNetworkUniqueUsersQuery from "./NetworkUniqueUsersQuery";

export default function useUniqueUsersQuery(
  options?: UseQueryOptions<string, Error, string, string[]>
) {
  const avalancheUniqueUsersQuery = useNetworkUniqueUsersQuery(
    AvalancheNetworkConfig
  );
  const polygonUniqueUsersQuery =
    useNetworkUniqueUsersQuery(PolygonNetworkConfig);
  const ethereumUniqueUsersQuery = useNetworkUniqueUsersQuery(
    EthereumNetworkConfig
  );

  return useQuery(
    ["uniqueUsers"],
    async () => {
      return (
        Number(avalancheUniqueUsersQuery.data) +
        Number(ethereumUniqueUsersQuery.data) +
        Number(polygonUniqueUsersQuery.data)
      ).toString();
    },
    {
      enabled:
        !!avalancheUniqueUsersQuery.data &&
        !!polygonUniqueUsersQuery.data &&
        !!ethereumUniqueUsersQuery.data,
      ...options,
    }
  );
}
