import { ResponsiveBar } from "@nivo/bar";
import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import useNetworkDailyVolumeQuery, {
  NetworkDailyFiatVolume,
  NetworkDailyVolume,
} from "hooks/query/volume/NetworkDailyVolumeQuery";
import * as React from "react";
import { format } from "date-fns";
import useNetworkDailyFiatVolumeQuery from "hooks/query/volume/NetworkDailyFiatVolumeQuery";

interface IOverallDailyVolumeProps {}

const OverallDailyVolume: React.FunctionComponent<IOverallDailyVolumeProps> = (
  props
) => {
  const ethereumDailyVolume = useNetworkDailyFiatVolumeQuery(
    EthereumNetworkConfig
  );
  const polygonDailyVolume =
    useNetworkDailyFiatVolumeQuery(PolygonNetworkConfig);
  const avalancheDailyVolume = useNetworkDailyFiatVolumeQuery(
    AvalancheNetworkConfig
  );

  const data = {} as {
    [key: string]: {
      [key: string]: any;
    };
  };

  if (
    ethereumDailyVolume.data &&
    polygonDailyVolume.data &&
    avalancheDailyVolume.data
  ) {
    for (const ethereumDayVolume of ethereumDailyVolume.data as NetworkDailyFiatVolume[]) {
      const key = ethereumDayVolume.timestamp;
      let currentValue = data[key];
      if (currentValue && data[key].ethereumDayVolume) {
        data[key].ethereumDayVolume += ethereumDayVolume.cumulativeFiatAmount;
      } else {
        data[key] = {
          ...data[key],
          timestamp: ethereumDayVolume.timestamp,
          ethereumDayVolume: ethereumDayVolume.cumulativeFiatAmount,
        };
      }
    }

    for (const polygonDayVolume of polygonDailyVolume.data as NetworkDailyFiatVolume[]) {
      const key = polygonDayVolume.timestamp;
      let currentValue = data[key];
      if (currentValue && data[key].polygonDayVolume) {
        data[key].polygonDayVolume += polygonDayVolume.cumulativeFiatAmount;
      } else {
        data[key] = {
          ...data[key],
          timestamp: polygonDayVolume.timestamp,
          polygonDayVolume: polygonDayVolume.cumulativeFiatAmount,
        };
      }
    }

    for (const avalancheDayVolume of avalancheDailyVolume.data as NetworkDailyFiatVolume[]) {
      const key = avalancheDayVolume.timestamp;
      let currentValue = data[key];
      if (currentValue && data[key].avalancheDayVolume) {
        data[key].avalancheDayVolume += avalancheDayVolume.cumulativeFiatAmount;
      } else {
        data[key] = {
          ...data[key],
          timestamp: avalancheDayVolume.timestamp,
          avalancheDayVolume: avalancheDayVolume.cumulativeFiatAmount,
        };
      }
    }
  }
  const processedData = Object.values(data) as any;
  return (
    <div className="h-[400px] py-6 px-4 border border-black/20 rounded-md flex flex-col">
      <div className="text-lg">Daily Volume (USD)</div>{" "}
      {!processedData || processedData.length === 0 ? (
        "..."
      ) : (
        <ResponsiveBar
          data={processedData.map((d: any) => ({
            ...d,
            Ethereum: d.ethereumDayVolume || 0,
            Polygon: d.polygonDayVolume || 0,
            Avalanche: d.avalancheDayVolume || 0,
            timestamp: format(
              new Date(parseInt(d.timestamp as string) * 1000),
              "dd/MM"
            ),
          }))}
          keys={["Ethereum", "Polygon", "Avalanche"]}
          indexBy="timestamp"
          margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
          padding={0.3}
          groupMode="stacked"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          valueFormat={(n) =>
            Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 2,
            }).format(n)
          }
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Token",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Liquidity",
            legendPosition: "middle",
            legendOffset: -40,
            format: (n) =>
              Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 2,
              }).format(n),
          }}
          labelSkipWidth={36}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Volume Chart"
        />
      )}
    </div>
  );
};

export default OverallDailyVolume;
