import React, { useMemo } from "react";

import EthereumDataCard from "components/DataCards/EthereumDataCard";
import { EthereumNetworkConfig, PolygonNetworkConfig } from "config/config";
import { UseQueryResult } from "react-query";
import { ResponsiveBar } from "@nivo/bar";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import LiquidityGraphCard from "components/LiquidityGraphCard";

const EthereumLiquidityEthereum: React.FC = (props) => {
  const ethereumLiquidity = useNetworkTokensLiquidityQuery(
    EthereumNetworkConfig
  );

  return (
    <LiquidityGraphCard
      data={ethereumLiquidity.data?.filter(
        (tokenLiquidity) => tokenLiquidity.tokenSymbol === "ETH"
      )}
      title={`${EthereumNetworkConfig.networkName} Liquidity`}
    />
  );
};

export default EthereumLiquidityEthereum;
