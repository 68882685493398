import Topbar from "components/Topbar";
import * as React from "react";
import { Navigate, useSearchParams } from "react-router-dom";

import {
  AvalancheNetworkConfig,
  EthereumNetworkConfig,
  INetworkConfig,
  PolygonNetworkConfig,
} from "config/config";
import useTransactionByDepositHashQuery from "hooks/query/TransactionByDepositHashQuery";
import DataCard from "components/DataCards/DataCard";

interface ISearchProps {}

const Networks = [
  EthereumNetworkConfig,
  PolygonNetworkConfig,
  AvalancheNetworkConfig,
];

const Search: React.FunctionComponent<ISearchProps> = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const depositHash = searchParams.get("depositHash") || "";
  const networkName = searchParams.get("network") || "";
  const fromChainNetworkConfig = Networks.find(
    (network) => network.networkName === networkName
  );

  if (!depositHash || !networkName || !fromChainNetworkConfig) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Topbar key={`${depositHash}-${networkName}`} />
      <SearchResults
        fromChainConfig={fromChainNetworkConfig}
        depositHash={depositHash}
      />
    </>
  );
};

interface ISearchResultsProps {
  fromChainConfig: INetworkConfig;
  depositHash: string;
}

const SearchResults: React.FunctionComponent<ISearchResultsProps> = ({
  fromChainConfig,
  depositHash,
}) => {
  const transactionByDepositHashQuery = useTransactionByDepositHashQuery(
    fromChainConfig,
    depositHash
  );

  if (transactionByDepositHashQuery.isLoading) {
    return <>...</>;
  }

  if (!transactionByDepositHashQuery.data) {
    return <p>No Deposit found</p>;
  }

  return (
    <div className="rounded-md border-hyphen-purple/20 border p-8 flex flex-col gap-10">
      <div className="text-xl text-black/70 font-medium">Transfer Details</div>
      <div className="flex flex-col text-base gap-8">
        <div className="flex flex-col gap-4">
          <div className="text-hyphen-purple text-lg font-medium">Deposit</div>
          <div className="grid grid-cols-[auto_1fr] gap-0.5 text-black/80 bg-black/10 border-black/10 border">
            {Object.entries(transactionByDepositHashQuery.data.from).map(
              ([key, value]) => {
                return (
                  <>
                    <div className="font-medium bg-white px-6 py-3">{key}</div>
                    <div className="bg-white px-6 py-3">
                      {value as React.ReactNode}
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-hyphen-purple text-lg font-medium">Exit</div>
          <div className="grid grid-cols-[auto_1fr] gap-0.5 text-black/80 bg-black/10 border-black/10 border">
            {Object.entries(transactionByDepositHashQuery.data.to).map(
              ([key, value]) => {
                return (
                  <>
                    <div className="font-medium bg-white px-6 py-3">{key}</div>
                    <div className="bg-white px-6 py-3">
                      {value as React.ReactNode}
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
