import { getNetworkTokens, INetworkConfig } from "config/config";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { ethers } from "ethers";

export type NetworkDailyFee = {
  cumulativeLpFee: number;
  cumulativeGasFee: number;
  cumulativeTransferFee: number;
  tokenAddress: string;
  timestamp: string;
};

export type NetworkDailyFeeSubgraphResponse = {
  cumulativeLpFee: string;
  cumulativeGasFee: string;
  cumulativeTransferFee: string;
  tokenAddress: string;
  timestamp: string;
};

export default function useNetworkDailyFeeQuery(networkConfig: INetworkConfig) {
  return useQuery<NetworkDailyFee[]>(
    ["dailyFees", networkConfig.networkName],
    async () => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const epochModSecondsInADay = currentTimestamp % 86400;
      const todayEpoch = currentTimestamp - epochModSecondsInADay;
      const beginningEpoch = todayEpoch - 86400 * 10;

      const subgraphResponse = await request(
        networkConfig.subgraphEndpoint,
        gql`
          {
            dailyAssetSentPerFromChainAndTokens(
              orderBy: timestamp
              orderDirection: desc
              where: { 
                timestamp_gt: ${beginningEpoch}
              }
            ) {
              cumulativeLpFee
              cumulativeGasFee
              cumulativeTransferFee
              tokenAddress
              timestamp
            }
          }
        `
      );

      const deduplicatedNetworkDailyFees = (
        subgraphResponse.dailyAssetSentPerFromChainAndTokens as Array<NetworkDailyFeeSubgraphResponse>
      ).reduce((acc, curr) => {
        const key = `${curr.tokenAddress}-${curr.timestamp}`;
        const decimals = getNetworkTokens(networkConfig.networkId).find(
          (token) =>
            token.contractAddress.toLowerCase() ===
            curr.tokenAddress.toLowerCase()
        )!.decimals;

        const typedCurrent: NetworkDailyFee = {
          ...curr,
          cumulativeLpFee: parseFloat(
            parseFloat(
              ethers.utils.formatUnits(curr.cumulativeLpFee, decimals)
            ).toFixed(3)
          ),
          cumulativeGasFee: parseFloat(
            parseFloat(
              ethers.utils.formatUnits(curr.cumulativeGasFee, decimals)
            ).toFixed(3)
          ),
          cumulativeTransferFee: parseFloat(
            parseFloat(
              ethers.utils.formatUnits(curr.cumulativeTransferFee, decimals)
            ).toFixed(3)
          ),
        };

        if (acc[key]) {
          acc[key].cumulativeLpFee += typedCurrent.cumulativeLpFee;
          acc[key].cumulativeGasFee += typedCurrent.cumulativeGasFee;
          acc[key].cumulativeTransferFee += typedCurrent.cumulativeTransferFee;
        } else {
          acc[key] = typedCurrent;
        }
        return acc;
      }, {} as { [key: string]: NetworkDailyFee });

      const networkDailyFees = Object.values(deduplicatedNetworkDailyFees);

      return networkDailyFees;
    },
    {
      staleTime: 60000,
    }
  );
}
