import React from "react";
import { Outlet } from "react-router-dom";

const App: React.FunctionComponent = (props) => {
  return (
    <div className="flex-grow mx-4 flex flex-col gap-4 pt-4 font-mono">
      <Outlet />
    </div>
  );
};

export default App;
