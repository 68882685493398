import { ResponsiveBar } from "@nivo/bar";
import * as React from "react";

interface ILiquidityData {
  availableLiquidity: number | string;
  availableFiatLiquidity: number | string;
  suppliedLiquidity: number | string;
  suppliedFiatLiquidity: number | string;
  tokenSymbol: string;
}

interface ILiquidityGraphCardProps {
  data?: ILiquidityData[];
  title: string;
}

const FiatLiquidityGraphCard: React.FunctionComponent<
  ILiquidityGraphCardProps
> = ({ data, title }) => {
  return (
    <div className="h-[400px] py-6 px-4 border border-black/20 rounded-md flex flex-col">
      <div className="text-lg">{title}</div>
      {data ? (
        <ResponsiveBar
          data={data.map((d) => ({
            Available: d.availableFiatLiquidity,
            Supplied: d.suppliedFiatLiquidity,
            ...d,
          }))}
          keys={["Available", "Supplied"]}
          indexBy="tokenSymbol"
          margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
          padding={0.3}
          groupMode="grouped"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          valueFormat={(n) =>
            Intl.NumberFormat("en-US", {
              notation: "compact",
              maximumFractionDigits: 2,
            }).format(n)
          }
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Token",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Liquidity",
            legendPosition: "middle",
            legendOffset: -40,
            format: (n) =>
              Intl.NumberFormat("en-US", {
                notation: "compact",
                maximumFractionDigits: 2,
              }).format(n),
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Liquidity Chart"
        />
      ) : (
        "..."
      )}
    </div>
  );
};

export default FiatLiquidityGraphCard;
