import { INetworkTokenConfig } from "./config";

export type ITokenConfig = {
  symbol: string;
  coinGeckoId: string;
} & {
  [chainId: number]: {
    contractAddress: string;
    transferOverhead: string;
    decimals: string;
  };
};

export const ETH: ITokenConfig = {
  symbol: "ETH",
  coinGeckoId: "ethereum",
  1: {
    contractAddress: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    transferOverhead: "97000",
    decimals: "18",
  },
  4: {
    contractAddress: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    transferOverhead: "97000",
    decimals: "18",
  },
  5: {
    contractAddress: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    transferOverhead: "97000",
    decimals: "18",
  },
  137: {
    contractAddress: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    transferOverhead: "124000",
    decimals: "18",
  },
  43113: {
    contractAddress: "0x7fcdc2c1ef3e4a0bcc8155a558bb20a7218f2b05",
    transferOverhead: "127000",
    decimals: "18",
  },
  43114: {
    contractAddress: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab",
    transferOverhead: "127000",
    decimals: "18",
  },
  80001: {
    contractAddress: "0xa6fa4fb5f76172d178d61b04b0ecd319c5d1c0aa",
    transferOverhead: "124000",
    decimals: "18",
  },
};

export const BICO: ITokenConfig = {
  symbol: "BICO",
  coinGeckoId: "biconomy",
  1: {
    contractAddress: "0xf17e65822b568b3903685a7c9f496cf7656cc6c2",
    transferOverhead: "121335",
    decimals: "18",
  },
  5: {
    contractAddress: "0xDdc47b0cA071682e8dc373391aCA18dA0Fe28699",
    transferOverhead: "121335",
    decimals: "18",
  },
  137: {
    contractAddress: "0x91c89A94567980f0e9723b487b0beD586eE96aa7",
    transferOverhead: "121335",
    decimals: "18",
  },
  80001: {
    contractAddress: "0xac42d8319ce458b22a72b45f58c0dcfeee824691",
    transferOverhead: "121335",
    decimals: "18",
  },
};

export const USDC: ITokenConfig = {
  symbol: "USDC",
  coinGeckoId: "usd-coin",
  1: {
    contractAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
    transferOverhead: "138000",
    decimals: "6",
  },
  5: {
    contractAddress: "0xb5B640E6414b6DeF4FC9B3C1EeF373925effeCcF",
    transferOverhead: "138000",
    decimals: "6",
  },
  137: {
    contractAddress: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
    transferOverhead: "116000",
    decimals: "6",
  },
  43114: {
    contractAddress: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664",
    transferOverhead: "127000",
    decimals: "6",
  },
  80001: {
    contractAddress: "0xdA5289fCAAF71d52a80A254da614a192b693e977",
    transferOverhead: "116000",
    decimals: "6",
  },
};

export const USDT: ITokenConfig = {
  symbol: "USDT",
  coinGeckoId: "tether",
  1: {
    contractAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
    transferOverhead: "135000",
    decimals: "6",
  },
  4: {
    contractAddress: "0xfab46e002bbf0b4509813474841e0716e6730136",
    transferOverhead: "135000",
    decimals: "18",
  },
  5: {
    contractAddress: "0x64ef393b6846114bad71e2cb2ccc3e10736b5716",
    transferOverhead: "135000",
    decimals: "18",
  },
  43113: {
    contractAddress: "0xB4E0F6FEF81BdFea0856bB846789985c9CFf7e85",
    decimals: "18",
    transferOverhead: "N/A",
  },
  137: {
    contractAddress: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
    transferOverhead: "130000",
    decimals: "6",
  },
  80001: {
    contractAddress: "0xeaBc4b91d9375796AA4F69cC764A4aB509080A58",
    transferOverhead: "130000",
    decimals: "18",
  },
};

export const tokens = [ETH, BICO, USDC, USDT];
