import AvalancheDataCard from "components/DataCards/AvalancheDataCard";
import { AvalancheNetworkConfig } from "config/config";
import useNetworkTotalFiatVolumeQuery, {
  NetworkTotalFiatVolume,
} from "hooks/query/volume/NetworkTotalFiatVolumeQuery";
import React, { useMemo } from "react";

interface IAvalancheTotalVolumeProps {}

const AvalancheTotalVolume: React.FunctionComponent<
  IAvalancheTotalVolumeProps
> = (props) => {
  const avalancheTotalVolumeQuery = useNetworkTotalFiatVolumeQuery(
    AvalancheNetworkConfig
  );

  // console.log(avalancheDayVolumeQuery.data);

  const cumulativeVolume = useMemo(() => {
    if (!avalancheTotalVolumeQuery.data) return undefined;

    return (avalancheTotalVolumeQuery.data as NetworkTotalFiatVolume[]).reduce(
      (acc, curr) => {
        return acc + curr.cumulativeFiatAmount || 0;
      },
      0
    );
  }, [avalancheTotalVolumeQuery.data]);

  return (
    <AvalancheDataCard
      title="Total Volume"
      body={
        cumulativeVolume
          ? `$ ${Number(cumulativeVolume.toFixed(0)).toLocaleString("en-US")}`
          : "..."
      }
    />
  );
};

export default AvalancheTotalVolume;
