import PolygonDataCard from "components/DataCards/PolygonDataCard";
import { PolygonNetworkConfig } from "config/config";
import useNetworkDayFiatVolumeQuery from "hooks/query/volume/NetworkDayFiatVolumeQuery";
import useNetworkDayVolumeQuery, {
  NetworkDayFiatVolume,
  NetworkDayVolume,
} from "hooks/query/volume/NetworkDayVolumeQuery";
import React, { useMemo } from "react";

const PolygonDayVolume: React.FC = ({}) => {
  const polygonDayVolumeQuery =
    useNetworkDayFiatVolumeQuery(PolygonNetworkConfig);

  // console.log(polygonDayVolumeQuery.data);

  const dayVolume = useMemo(() => {
    if (!polygonDayVolumeQuery.data) return undefined;

    const dayVolumeFloat = (
      polygonDayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    return "$ " + parseInt(dayVolumeFloat.toString()).toLocaleString("en-US");
  }, [polygonDayVolumeQuery.data]);

  return <PolygonDataCard title="24 Hour Volume" body={dayVolume || "..."} />;
};

export default PolygonDayVolume;
