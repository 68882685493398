import React from "react";

import PolygonDataCard from "components/DataCards/PolygonDataCard";
import { PolygonNetworkConfig } from "config/config";
import useNetworkUniqueUsersQuery from "hooks/query/uniqueUsers/NetworkUniqueUsersQuery";

const PolygonUniqueUsers: React.FC = (props) => {
  const polygonUniqueUsersQuery =
    useNetworkUniqueUsersQuery(PolygonNetworkConfig);

  return (
    <PolygonDataCard
      title="Unique Users"
      body={polygonUniqueUsersQuery.data || "..."}
    />
  );
};

export default PolygonUniqueUsers;
